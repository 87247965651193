import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CarRental = () => {
  const [offersState, setOffersState] = useState(null);

  useEffect(() => {
    window.CT = {
      ABE: {
        Settings: {
          proxyURL: "https://www.gaia-travels.com/otaproxy/",
          clientID: 142215,
          language: "FI",
        },
      },
    };

    const myDate = new Date();
    const myStamp =
      "" +
      myDate.getDate() +
      myDate.getMonth() +
      myDate.getFullYear() +
      myDate.getHours() +
      myDate.getMinutes();

    const script = document.createElement("script");
    script.src = "https://ajaxgeo.cartrawler.com/scripts/ct_svn.js?" + myStamp;
    document.body.appendChild(script);

    setTimeout(() => {
      const script2 = document.createElement("script");
      script2.src =
        "https://ajaxgeo.cartrawler.com/ctabe-" +
        window.CARTRAWLER.SVNInfo.revision +
        "/abe/js/ct_loader.js";
      document.body.appendChild(script2);
    }, 1000);

    fetch(
      "https://www.gaia-travels.com/otaproxy/?geturl=http://www.cartrawler.com/feeds/486083_OyGaiaTravelsLtd_ENEUR.xml"
    )
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        let parsedOffers = [];
        const offers = data.getElementsByTagName("Offer");
        for (let i = 0; i < offers.length; i++) {
          const offer = offers[i];
          let offer_link = offer.textContent;
          offer_link = "?" + offer_link.slice(offer_link.indexOf("&") + 1);
          parsedOffers.push({
            link: offer_link,
            location_name: offer.getAttribute("LocationName"),
            amount: offer.getAttribute("Amount"),
          });
        }
        setOffersState(parsedOffers);
      });
  }, []);

  let offersTable;
  if (offersState) {
    offersTable = offersState.map((offer, i) => {
      if (i !== 0 && (i + 1) % 2 === 0) {
        return (
          <>
            <div key={offer.link} className="col-lg-6 pb-2">
              <a
                href={offer.link}
                className="btn btn-outline-primary car-rental-button"
              >
                <h6 className="label-heading">{offer.location_name}</h6>
                <p className="text-sm font-weight-bold">
                  €{offer.amount}/päivä
                </p>
              </a>
            </div>
            <div className="w-100"></div>
          </>
        );
      } else {
        return (
          <div key={offer.link} className="col-lg-6 pb-2">
            <a
              href={offer.link}
              className="btn btn-outline-primary car-rental-button"
            >
              <p className="label-heading">{offer.location_name}</p>
              <p className="text-sm font-weight-bold">€{offer.amount}/päivä</p>
            </a>
          </div>
        );
      }
    });
  }
  return (
    <>
      <Layout>
        <SEO title={"Vuokra-autot"} />
        <section className="hero-home dark-overlay mb-5">
          <img
            className="img-fluid bg-image blog-title-image"
            src="https://www.gaia-travels.com/media/static/Web/img/car_banner.jpg"
            alt="Car banner"
          />

          <div className="container py-7">
            <div className="overlay-content text-center text-white">
              <h1 className="display-3 font-weight-bold text-shadow mb-0 blog-page-title">
                Autovuokraus
              </h1>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div id="abe_ABE">
                <noscript>YOUR BROWSER DOES NOT SUPPORT JAVASCRIPT</noscript>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">{offersTable}</div>
            </div>
            <div className="col-md-4">
              <ul className="list-unstyled">
                <li>
                  <p>30 000 toimipistettä eri puolilla maailmaa</p>
                </li>
                <li>
                  <p>800 autovuokraamoa</p>
                </li>
                <li>
                  <p>Helppo kolmivaiheinen varaustoiminto</p>
                </li>
                <li>
                  <p>Laaja valikoima ajoneuvoja</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <img
                className="img-fluid"
                src="https://www.gaia-travels.com/media/static/Web/img/car_suppliers.jpg"
                alt="Car suppliers"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CarRental;
